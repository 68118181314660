import React from 'react';

const ContactUs = () => {
    return (
        <div>
            <p className='display-2'>    cont Us</p>

        </div>
    );
};

export default ContactUs;
