import React from 'react';

const Materials = () => {
    return (
        <div>
           <p className='display-2'>Materials</p>
        </div>
    );
};

export default Materials;
