import React from 'react';

const Teachers = () => {
    return (
        <div>
            <p className='display-2'>Наши учителя</p>
        </div>
    );
};

export default Teachers;
