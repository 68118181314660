import React from 'react';

const Timetable = () => {
    return (
        <div>
            <p className='display-2'>расписание</p>

        </div>
    );
};

export default Timetable;
