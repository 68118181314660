import React from 'react';

const Gallery = () => {
    return (
        <div>
            <p   className='display-2'   >Галерея</p>

        </div>
    );
};

export default Gallery;
