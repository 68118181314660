import React from 'react';

const Advices = () => {
    return (
        <div>
            <p className='display-2'>Советы родителям</p>

        </div>
    );
};

export default Advices;
